"use strict";

const btnContact = document.querySelector<HTMLButtonElement>("#btn-contact")!;
const contacts = document.querySelector<HTMLElement>("#contacts")!;
const header = document.querySelector<HTMLElement>("#header")!;
const up = document.querySelector<HTMLButtonElement>("#up")!;

btnContact.addEventListener("click", () => {
  contacts.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
});

up.addEventListener("click", () => {
  header.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
});

window.onscroll = () => {
  if (window.scrollY > 150) {
    up.classList.add("show");
  } else {
    up.classList.remove("show");
  }
};
